import { Box, Typography } from "@mui/material";

export default function Mission() {
  return (
    <Box width={"100%"} sx={{ background: "rgb(51, 52, 63)" }}>
      <Box sx={{ padding: "10% 5%", textAlign: "center" }}>
        <Typography fontSize={32} color={"white"}>
          OUR MISSION
        </Typography>
        <Typography pt={"2rem"} fontSize={16} color={"white"}>
          Empowering Fathers, Restoring Bonds.
        </Typography>
        <Typography pt={"1rem"} fontSize={16} color={"white"}>
          At SOS PAPA, we are dedicated to championing the rights of fathers unjustly separated from their children.
        </Typography>
        <Typography pt={"1rem"} fontSize={16} color={"white"}>
          Our mission is to provide unwavering support, legal guidance, and a platform for advocacy, fostering stronger parent-child relationships and
          advocating for fairness within the legal system. Together, we strive to ensure that no father's voice goes unheard and no child is
          needlessly deprived of a loving parent.
        </Typography>
      </Box>
    </Box>
  );
}
