import { Box, Typography } from "@mui/material";

export default function Saying() {
  return (
    <Box width={"100%"} sx={{ background: "rgb(51, 52, 63)" }}>
      <Box sx={{ padding: "5% 5%" }}>
        <Typography pt={"2rem"} fontSize={20} color={"white"}>
          "It is easier for a father to have children than for children to have a real father."
        </Typography>
        <Typography pt={"2rem"} fontSize={16} color={"white"}>
          Pope John XXIII
        </Typography>
      </Box>
    </Box>
  );
}
