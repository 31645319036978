import { Box, Grid, Typography } from "@mui/material";
import MediaCard from "../../Components/Card/MediaCard";

export default function Initiatives() {
  const [heightMedia, heightContent] = [200, 210];
  return (
    <Box p={3} sx={{ background: "white" }}>
      <Typography fontSize={28} m={2}>
        Our Initiatives
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <MediaCard
            header={"Legal Advocacy and Education Program"}
            body={
              "Establish a comprehensive program that offers legal assistance and education to fathers navigating the complexities of child custody and visitation disputes. Collaborate with legal experts to provide legal consultations, workshops, and resources."
            }
            image={"./images/legaladvocacy.jpg"}
            heightMedia={heightMedia}
            heightContent={heightContent}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MediaCard
            header={"Father-Child Reunification Retreats"}
            body={
              "Promote retreats or camps designed to facilitate emotional healing and reconnection between fathers and their children who have been separated due to divorce or other circumstances. These retreats could involve bonding activities, workshops on effective communication, and counseling sessions by professionals."
            }
            image={"./images/retreats.jpg"}
            heightMedia={heightMedia}
            heightContent={heightContent}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MediaCard
            header={"Awareness and Advocacy Campaigns"}
            body={
              "Launch targeted awareness campaigns that shed light on the challenges faced by fathers who are stigmatized and unfairly separated from their children. Utilize social media, workshops, seminars, and partnerships with other organizations to raise awareness about the importance of equal and fair parenting rights."
            }
            image={"./images/awareness.jpg"}
            heightMedia={heightMedia}
            heightContent={heightContent}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
