import { Box, Typography } from "@mui/material";
import Footer from "../Home/Footer";

export default function ContactUs() {
  return (
    <Box>
      <Box width={"calc(100vw)"} height={20} display={{ lg: "block", md: "block", xs: "none" }} />
      <Box mb={10}>
        <Typography fontSize={28} mt={5} textAlign={"center"}>
          Follow us on Facebook
        </Typography>
        <Typography fontSize={16} textAlign={"center"}>
          <a href="https://www.facebook.com/sospapamoris">https://www.facebook.com/sospapamoris</a>
        </Typography>
        <Typography fontSize={28} mt={5} textAlign={"center"}>
          Follow us on LinkedIn
        </Typography>
        <Typography fontSize={16} textAlign={"center"}>
          <a href="https://www.linkedin.com/in/sos-papa-moris-42776728a/">https://www.linkedin.com/in/sos-papa-moris-42776728a/</a>
        </Typography>
        <Typography fontSize={28} mt={5} textAlign={"center"}>
          Call us on
        </Typography>
        <Typography fontSize={16} textAlign={"center"}>
          5797 6285
        </Typography>
        <Typography fontSize={16} textAlign={"center"}>
          on weekdays as from 20H30
        </Typography>
        <Typography fontSize={28} mt={5} textAlign={"center"}>
          Leave us an email
        </Typography>
        <Typography fontSize={16} textAlign={"center"}>
          sospapa@live.com
        </Typography>
        <Typography fontSize={20} m={5} textAlign={"center"}>
          SOSPAPA Moris is a benevolent non-profit organisation dedicated to fighting for fathers' rights in Mauritius.
        </Typography>
      </Box>

      <Footer />
    </Box>
  );
}
