import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Box pt={6} pb={3} sx={{ background: "white" }}>
      <Typography textAlign={"center"} fontSize={32}>
        SOS PAPA MORIS
      </Typography>
      <Typography mt={5} textAlign={"center"} fontSize={20}>
        sospapa@live.com
      </Typography>
      <Typography mt={5} textAlign={"center"} fontSize={16} display={"flex"} flexWrap={"wrap"} justifyContent={"center"}>
        <span>©2023 by SOS PAPA MORIS</span>
        <span>&nbsp;-&nbsp;</span>
        <Link to={"https://www.facebook.com/profile.php?id=61552512733219"} target="_blank">
          <span>Powered by Maurifast Ltd</span>
        </Link>
      </Typography>
    </Box>
  );
}
