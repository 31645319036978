import styled from "@emotion/styled";
import { Autocomplete, Box, Button, Chip, Grid, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { getDocumentByPath, setDocumentByPath } from "../../services/firebase/DbUtils";
import { useSnackbar } from "notistack";
import { Search } from "@mui/icons-material";
import Auth from "../../services/firebase/Auth";

const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: blue;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: blue;
    }
  }
`;

export default function EditPost() {
  const { enqueueSnackbar } = useSnackbar();
  const [blogId, setBlogId] = useState("");
  const [title, setTitle] = useState("");
  // const [image, setImage] = useState("");
  const [body, setBody] = useState("");
  const [source, setSource] = useState("");
  const [createdOn, setCreatedOn] = useState(new Date());
  const [createdBy, setCreatedBy] = useState("");
  const [tags, setTags] = useState([]);
  const [editDisabled, setEditDisabled] = useState(true);

  function handleAutoComplete(event, value) {
    setTags(value);
  }

  function handleEditID(value) {
    setBlogId(value);
    setBody("");
    // setImage("");
    setTitle("");
    setSource("");
    setTags([]);
    setEditDisabled(true);
  }

  async function getBlogById() {
    const docId = blogId.trim().toLowerCase();
    const result = await getDocumentByPath(`/blogs/${docId}`);
    if (result) {
      setBody(result.body);
      // setImage(result.image);
      setTitle(result.title);
      setTags(result.tags);
      setSource(result.source);
      setCreatedBy(result.createdBy);
      setCreatedOn(result.createdOn);
      setEditDisabled(false);
      enqueueSnackbar(`Blog with id ${docId} found`, { variant: "success" });
    } else {
      setEditDisabled(true);
      enqueueSnackbar(`No blog with id ${docId} found`, { variant: "error" });
    }
  }

  async function editPost() {
    const docId = blogId.trim().toLowerCase();
    await setDocumentByPath(`/blogs/${docId}`, {
      title,
      body,
      tags,
      // image,
      source,
      createdBy,
      createdOn,
      modifiedOn: new Date(),
      modifiedBy: Auth.currentUser.email,
    });
    enqueueSnackbar(`Blog with id ${docId} updated`, { variant: "success" });
  }

  return (
    <Box width={"calc(100vw)"} p={5}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <StyledTextField fullWidth label="Blog ID" value={blogId} onChange={({ target: { value } }) => handleEditID(value)} />
        </Grid>
        <Grid item xs={2}>
          <IconButton size="large" onClick={getBlogById}>
            <Search />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <StyledTextField fullWidth label="Title" value={title} onChange={({ target: { value } }) => setTitle(value)} />
        </Grid>
        {/* <Grid item xs={12}>
          <StyledTextField fullWidth label="Post Image (Google Drive File ID)" value={image} onChange={({ target: { value } }) => setImage(value)} />
        </Grid> */}
        <Grid item xs={12}>
          <StyledTextField fullWidth multiline rows={15} label="Body (HTML)" value={body} onChange={({ target: { value } }) => setBody(value)} />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            clearIcon={false}
            options={[]}
            value={tags}
            onChange={handleAutoComplete}
            freeSolo
            multiple
            renderTags={(value, props) => tags.map((option, index) => <Chip label={option} {...props({ index })} />)}
            renderInput={(params) => <StyledTextField label="Add Keywords" {...params} />}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField fullWidth label="Source" value={source} onChange={({ target: { value } }) => setSource(value)} />
        </Grid>
        <Grid item md={2} xs={12}>
          <Button
            onClick={editPost}
            disabled={editDisabled}
            sx={{
              width: "100%",
              backgroundColor: "#3c52b2",
              color: "#fff",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            Update Post
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
