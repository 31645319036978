import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getBlogs } from "../../services/firebase/DbUtils";
import MediaCard from "../../Components/Card/MediaCard";

export default function News() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function getNews() {
      setBlogs(await getBlogs("blogs"));
      // blogs.map(({ image, title }, key) => console.log(image));
    }
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p={3} width={"calc(100vw)"}>
      <Grid container spacing={2}>
        {blogs.map(({ image, title, id }, key) => (
          <Grid key={key} item xs={12} md={4}>
            <MediaCard
              id={id}
              header={title}
              heightContent={70}
              heightMedia={150}
              cardAction={true}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
