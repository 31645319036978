import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { isLoggedInRS } from "../../services/redux/UserStateSlice";

function ResponsiveAppBar({ enlarge }) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const isLoggedIn = useSelector(isLoggedInRS);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function getBannerHeight() {
    // if (enlarge) return "40px 0 40px 0";
    if (enlarge) return 90;
    return 60;
  }

  function getPages() {
    if (isLoggedIn)
      return [
        { label: "Home", path: "home" },
        { label: "About us", path: "about-us" },
        { label: "News", path: "news" },
        { label: "Contact us", path: "contact-us" },
        { label: "Create Post", path: "create-post" },
        { label: "Update Post", path: "edit-post" },
        { label: "Log out", path: "logged-out" },
      ];

    return [
      { label: "Home", path: "home" },
      { label: "About us", path: "about-us" },
      { label: "News", path: "news" },
      { label: "Contact us", path: "contact-us" },
    ];
  }

  return (
    <AppBar elevation={1} component="nav" sx={{ padding: 0, transition: "0.8s", maxHeight: getBannerHeight() }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters variant="dense">
          <Box ml={3} sx={{ display: { xs: "none", md: "flex" } }}>
            <img src="/images/banner.jpg" alt="banner" style={{ transition: "0.8s", maxHeight: getBannerHeight() }} />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {getPages().map(({ label, path }) => (
                <MenuItem component={Link} to={path} key={path} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            SOS PAPA
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} justifyContent={"center"}>
            {getPages().map(({ label, path }) => (
              <Button component={Link} to={path} key={path} sx={{ display: "block", color: "inherit" }}>
                {label}
              </Button>
            ))}
          </Box>
          <Box>
            <a href="https://www.linkedin.com/in/sos-papa-moris-42776728a/" target="_blank" rel="noreferrer">
              <Tooltip title="Follow us on LinkedIn">
                <img width={24} src="/images/linkedin.png" alt="follow us on linkedin" style={{ borderRadius: "15%" }} />
              </Tooltip>
            </a>
          </Box>
          <Box ml={1}>
            <a href="https://www.facebook.com/sospapamoris" target="_blank" rel="noreferrer">
              <Tooltip title="Follow us on Facebook">
                <img width={24} src="/images/fb_icon.png" alt="follow us on facebook" style={{ borderRadius: "15%" }} />
              </Tooltip>
            </a>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
