import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isLoggedInRS } from "../../services/redux/UserStateSlice";
import Auth from "../../services/firebase/Auth";

export default function LoggedOut() {
  const isLoggedIn = useSelector(isLoggedInRS);

  useEffect(() => {
    Auth.signOut();
  }, []);

  return <>{isLoggedIn ? "You are still logged in" : "You've been logged out"}</>;
}
