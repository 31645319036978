import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import App from "./App";

const Storage = getStorage(App);

export default Storage;

export async function uploadFileToPath(file, path) {
  const storageRef = ref(Storage, path);
  await uploadBytes(storageRef, file);
}

export async function getBlogImgById(id) {
  const path = `/blogs/${id}.jpg`;
  const imageRef = ref(Storage, path);
  const url = await getDownloadURL(imageRef);
  return url;
}
