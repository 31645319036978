import { createSlice } from "@reduxjs/toolkit";

export const AppUISlice = createSlice({
  name: "AppUI",
  initialState: {
    isLoading: true,
    sideBarOpen: false,
    currentPath: [],
    userMenu: [],
    adminMenu: [],
  },
  reducers: {
    setIsLoading(state, actions) {
      state.isLoading = actions.payload;
    },
    setSideBarOpen(state, actions) {
      state.sideBarOpen = actions.payload;
    },
    setUserMenu(state, actions) {
      state.userMenu = [...actions.payload];
    },
    setAdminMenu(state, actions) {
      state.adminMenu = [...actions.payload];
    },
    setCurrentPath(state, actions) {
      state.currentPath = [...actions.payload];
    },
  },
});

export const { setIsLoading, setSideBarOpen, setUserMenu, setAdminMenu, setCurrentPath } = AppUISlice.actions;

export const sideBarOpenRS = (state) => state.appUI.sideBarOpen;
export const isLoadingRS = (state) => state.appUI.isLoading;
export const userMenuRS = (state) => state.appUI.userMenu;
export const adminMenuRS = (state) => state.appUI.adminMenu;
export const currentPathRS = (state) => state.appUI.currentPath;

export default AppUISlice.reducer;
