import "./NotFound.css";
import LoadingSuspense from "./../../services/router/LoadingSuspense";
import { useEffect, useState } from "react";

const NotFound = () => {
  const [stall, setStall] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setStall(false);
    }, 10000);
  }, []);

  return (
    <>
      {stall ? (
        <LoadingSuspense />
      ) : (
        <div className="content404">
          <div id="message">
            <h2>404</h2>
            <h1>Page Not Found</h1>
            <p>The specified file was not found on this website. Please check the URL for mistakes and try again.</p>
            <h3>Why am I seeing this?</h3>
            <p>This page was generated by the our servers..</p>
          </div>
        </div>
      )}
    </>
  );
};

export default NotFound;
