import { Box } from "@mui/material";
import Carousel from "./Carousel";
import Mission from "./Mission";
import Initiatives from "./Initiatives";
import Achievements from "./Achievements";
import Footer from "./Footer";
import Latest from "./Latest";
// import HelpYou from "./HelpYou";
// import Statistics from "./Statistics";

export default function Home() {
  return (
    <Box>
      <Box width={"100%"} height={20} display={{ lg: "block", md: "block", xs: "none" }} />
      <Carousel />
      <Latest />
      <Mission />
      <Initiatives />
      <Achievements />
      <Footer />
    </Box>
  );
}
