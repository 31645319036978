import { Box, Typography } from "@mui/material";

export default function Statement() {
  return (
    <Box width={"100%"} sx={{ background: "#f0f0f0" }}>
      <Box sx={{ padding: "5% 5%" }}>
        <Typography pt={"2rem"} fontSize={32}>
          SOS PAPA MORIS
        </Typography>
        <Typography pt={"2rem"} fontSize={24}>
          Fondation et Vision de SOS PAPA MORIS
        </Typography>
        <Typography pt={"2rem"} fontSize={16}>
          SOSPAPA MORIS est une organisation non-gouvernementale qui défend les droits des pères à Maurice en se basant sur les principes d'équité,
          d'égalité des opportunités, et de la neutralité de genre. L'ONG a été co-fondée en 2007 par Anil Callea et Darmen Appadoo, qui ont eux-mêmes
          vécu des divorces difficiles et livré plusieurs batailles pour la garde de leurs enfants (mais ont compris que cette bataille légale était
          perdue d'avance parce que la loi était discriminatoire envers les papas).
        </Typography>
        <Typography pt={"2rem"} fontSize={24}>
          Voilà la création de SOS PAPA
        </Typography>
        <Typography pt={"2rem"} fontSize={16}>
          Ils ont ainsi souhaité créer une plateforme pour soutenir d'autres pères confrontés à ces défis similaires et pour sensibiliser sur
          l'importance du rôle du père pour le bien-être de leurs enfants. Le nom SOS PAPA MORIS a été sélectionné lors de la première réunion entre
          un groupe d'amis sous un arbre au parcours du Dauguet à Tranquebar en pleine nature. La mission de l'ONG vise à aider les pères en détresse
          et à plaider en leur faveur auprès des autorités afin qu'ils puissent exercer leurs droits paternels (d'après les lois, ces papas n'auront
          pas la garde de leurs enfants et devront se contenter d'un droit de visite et d'un droit d'hébergement minimal ainsi que de payer la pension
          alimentaire obligatoire pour leur enfant. Ironiquement, tous ces droits supposément acquis ne seront obtenus qu'après une très longue et
          coûteuse bataille légale en cour) pour le bien-être de leurs enfants avec pour objectif de réaliser cette vision d'un avenir meilleur pour
          ces derniers. L'ONG vise également à promouvoir l'égalité des genres et à remettre en question le système qui a fortement tendance à
          favoriser la mère et à minimiser le rôle d'un père vis-à-vis de leurs enfants.
        </Typography>
        <Typography pt={"2rem"} fontSize={24}>
          La vision de l'ONG
        </Typography>
        <Typography pt={"2rem"} fontSize={16}>
          Contrairement aux croyances populaires, l'ONG n'a jamais l'intention de minimiser le rôle de la maman dans une relation saine pour les
          enfants, mais au contraire, elle vise à garantir une parentalité juste et égale dans l'intérêt supérieur de l'enfant, en particulier pour
          ceux issus de couples divorcés ou séparés. Beaucoup de ces enfants ont tendance à adopter un mode de vie malsain en partie due à l'absence
          'forcée' de leur papa biologique et deviennent ainsi un problème pour la société et, par conséquent, pour les autorités avant même qu'ils
          n'atteignent l'âge de la maturité (délinquance juvénile).
        </Typography>
        <Typography pt={"2rem"} fontSize={24}>
          Délinquance juvénile
        </Typography>
        <Typography pt={"2rem"} fontSize={16}>
          Beaucoup de cas dans les délits de délinquance juvénile où les jeunes sont impliqués sont issus de foyers et de familles brisés ou désunis.
          SOS PAPA MORIS a déjà organisé plusieurs activités et événements afin de sensibiliser le public et les autorités, tels que des sessions
          d'écoute, des conseils pratiques, des manifestations ainsi que des campagnes.
        </Typography>
        <Typography pt={"2rem"} fontSize={24}>
          Violence domestique
        </Typography>
        <Typography pt={"2rem"} fontSize={16}>
          On devrait aussi souligner que SOSPAPA est l'unique organisation qui milite contre les violences domestiques infligées aux hommes.
        </Typography>
        <Typography pt={"2rem"} fontSize={24}>
          L'appel à la solidarité pour un avenir
        </Typography>
        <Typography pt={"2rem"} fontSize={16}>
          L'organisation invite ainsi toute forme de soutien, pas seulement financier mais aussi d'autres formes de soutien comme des opportunités
          afin de bien mener des enquêtes pour assurer une avenir saine pour les enfants et leur papa.
        </Typography>
      </Box>
    </Box>
  );
}
