import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDocumentByPath } from "../../services/firebase/DbUtils";
import { Box, Typography } from "@mui/material";
import ShareButtons from "./ShareButtons";
import MetaTags from "./MetaTags";
import { getBlogImgById } from "../../services/firebase/Storage";

export default function Blog() {
  const { blogid } = useParams();
  const [blog, setBlog] = useState({});
  const [imgUrl, setImgUrl] = useState("/images/blog-banner.jpeg");

  useEffect(() => {
    async function getDoc() {
      setBlog(await getDocumentByPath(`/blogs/${blogid}`));
      setImgUrl(await getBlogImgById(blogid));
    }
    getDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width={"calc(100vw)"} p={3}>
      <MetaTags title={blog.title} blogId={blogid} pageImage={imgUrl}/>
      <img
        width={"100%"}
        height={"400px"}
        src={imgUrl}
        // src={blog.image ? `https://drive.google.com/uc?id=${blog.image}` : "/images/blog-banner.jpeg"}
        alt="Blog"
        style={{ objectFit: "cover" }}
      />
      <Typography fontSize={32}>{blog.title}</Typography>
      <div dangerouslySetInnerHTML={{ __html: blog.body }} />
      {blog.source ? (
        <p>
          <strong>Source:&nbsp;</strong>
          <a href={blog.source}>{blog.source}</a>
        </p>
      ) : (
        <></>
      )}
      <ShareButtons blogid={blogid} />
    </Box>
  );
}

// useEffect(() => {
//   // var xhr = new XMLHttpRequest();
//   if (blog.body) {
//     var url = `https://drive.google.com/uc?export=download&id=1k8S5BucxrzhaoR0tFq3Hmy4W9BUuJbSv`;
//     fetch(url, { mode: "no-cors" })
//       .then((response) => {
//         console.log(response);
//         response.text();
//       })
//       .then((content) => {
//         console.log("File content:", content);
//         // Do something with the content
//       })
//       .catch((error) => {
//         console.error("Error fetching file:", error);
//       });
//   }
//   // xhr.open("GET", url);
//   // xhr.onload = function () {
//   //   var value = xhr.responseText;
//   //   console.log(value);
//   // };
//   // xhr.onerror = function (e) {
//   //   console.log("ERROR LOADING THE FILE " + e);
//   // };
//   // xhr.send();
// }, [blog.body]);
