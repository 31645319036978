import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActions, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { getBlogImgById } from "../../services/firebase/Storage";

export default function MediaCard({ id, header, body, image, heightMedia, heightContent, cardAction }) {
  const theme = useTheme();
  const large_screen = useMediaQuery(theme.breakpoints.up("md"));
  const [imgUrl, setImgUrl] = useState("/images/blog-banner.jpeg");

  useEffect(() => {
    async function getDoc() {
      setImgUrl(await getBlogImgById(id));
    }
    if (image) setImgUrl(image);
    else getDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardMedia sx={{ height: heightMedia }} image={imgUrl} title="sos papa" />
      <CardContent sx={{ minHeight: heightContent, paddingBottom: 0 }}>
        <Typography gutterBottom sx={{ overflowWrap: "anywhere" }}>
          {large_screen ? `${header.substr(0, 55)}...` : header}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {body}
        </Typography>
      </CardContent>
      {cardAction ? (
        <CardActions>
          <Button component={Link} to={`../blogs/${id}`} size="small" color="inherit">
            Read more...
          </Button>
        </CardActions>
      ) : (
        <></>
      )}
    </Card>
  );
}
