import { useEffect, useState } from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";

export default function Topbar() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return <ResponsiveAppBar enlarge={offset < 50} />;
}
