import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getLatestBlogs } from "../../services/firebase/DbUtils";
import MediaCard from "../../Components/Card/MediaCard";

export default function Latest() {
  const [heightMedia, heightContent] = [150, 70];
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function getNews() {
      setBlogs(await getLatestBlogs("blogs", 3));
    }
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p={3} sx={{ background: "#eee" }}>
      <Typography fontSize={28} m={2}>
        Latest News
      </Typography>
      <Grid container spacing={3}>
        {blogs.map(({ image, title, id }, key) => (
          <Grid key={key} item xs={12} md={4}>
            <MediaCard
              id={id}
              header={title}
              heightContent={heightContent}
              heightMedia={heightMedia}
              cardAction={true}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
