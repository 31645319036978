import { createTheme } from "@mui/material";
import { blue, grey, orange } from "@mui/material/colors";

export const flavour = {
  palette: {
    light: {
      mode: "light",
      primary: { main: "#fff", contrastText: "#cb3433" },
      secondary: { main: "#00adee", contrastText: "#cb3433" },
      neutral: { main: grey[[800]] },
      background: { default: "#f0f0f0", paper: "#f0f0f0" },
    },
    dark: {
      mode: "dark",
      primary: { main: orange[700], contrastText: "#fff" },
      secondary: { main: blue[300], contrastText: "#fff" },
      neutral: { main: grey[[300]] },
      background: { default: "#000", paper: "#000" },
    },
  },
  typography: {
    fontFamily: "Open Sans",
    textDecorationColor: "black",
  },
};

function setFlavour() {
  // Set Theme
  const theme = createTheme({
    palette: flavour.palette.light,
    typography: flavour.typography,
  });

  return theme;
}

export default setFlavour;
