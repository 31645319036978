import Auth from "../../services/firebase/Auth";
import { setIsLoading } from "../../services/redux/AppUISlice";
import { setUserLoggedIn } from "../../services/redux/UserStateSlice";

export function setupApp(dispatch) {
  // Stall to check if user is already logged in
  setTimeout(() => {
    dispatch(setIsLoading(false));
  }, 1000);

  // Listen to user login status
  Auth.onAuthStateChanged(async (user) => {
    dispatch(setUserLoggedIn(user !== null));
  });
}
