import { configureStore } from "@reduxjs/toolkit";
import AppUISlice from "./AppUISlice";
import UserStateSlice from "./UserStateSlice";

const store = configureStore({
  reducer: {
    appUI: AppUISlice,
    userState: UserStateSlice,
  },
  devTools: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
