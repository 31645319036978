import { StarBorder } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export default function Achievements() {
  const list = [
    {
      title: "Landmark Legal Victory",
      body: ["Proposal to change name of Ministry of Women to that of Ministry to Gender which has been implemented since 2005."],
    },
    {
      title: "Community Support and Outreach",
      body: ["Proposition for the nomination of male and female judges in Family Court which is still standard practice."],
    },
    {
      title: "Policy Advocacy and Legislative Change",
      body: [
        "Promoting fair and friendly family laws.",
        "First organisation advocating fathers rights and also campaigning against domestic violence and abuse against men",
      ],
    },
  ];
  return (
    <Box display={"flex"}>
      <Box
        width="50%"
        component="img"
        src="./images/achievements.jpg"
        alt="Achievement"
        display={{ md: "block", xs: "none" }}
        sx={{ objectFit: "cover" }}
      />
      <Box width={{ xs: "100%", md: "50%" }} padding={"3% 7%"} alignSelf={"center"}>
        <Typography fontSize={24} fontWeight={600}>
          Our Achievements
        </Typography>
        {list.map((content, key) => achievementListItem(content, key))}
      </Box>
    </Box>
  );
}

function achievementListItem({ title, body }, key) {
  return (
    <Box key={key}>
      <Box display={"flex"} mt={4} alignItems={"center"}>
        <StarBorder sx={{ color: "primary.contrastText", margin: 2 }} />
        <Typography fontSize={20}>{title}</Typography>
      </Box>
      {body.map((item, key) => (
        <Typography key={key} ml={2} fontSize={16}>
          {item}
        </Typography>
      ))}
    </Box>
  );
}
