import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBL9sfizASCVTurNp4zRegjp6mOsxwb-3A",
  authDomain: "sospapa-moris.firebaseapp.com",
  projectId: "sospapa-moris",
  storageBucket: "sospapa-moris.appspot.com",
  messagingSenderId: "740252242933",
  appId: "1:740252242933:web:1b582a5d3a227fc9b286bc",
  measurementId: "G-7K0GGPKDP7",
};

const App = initializeApp(firebaseConfig);

export default App;
