import { Box } from "@mui/material";
import Footer from "../Home/Footer";
import Statement from "./Statement";
import Saying from "./Saying";

export default function AboutUs() {
  return (
    <Box>
      <Box width={"calc(100vw)"} height={20} display={{ lg: "block", md: "block", xs: "none" }} />
      <Saying />
      <Statement />
      <Footer />
    </Box>
  );
}
