import { doc, onSnapshot, query, getDoc, collection, setDoc, addDoc, getDocs, orderBy, limit } from "firebase/firestore";
import Auth from "./Auth";
import fbdb from "./fbdb";
import { setUserProfile } from "../redux/UserStateSlice";

export function subsUserProfile(dispatch) {
  const q = query(doc(fbdb, "Users", Auth.currentUser.uid));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    dispatch(setUserProfile(snapshot.data()));
  });
  return () => unsubscribe();
}

export function subsCollections(dispatch, paths) {
  for (const iot in paths) {
    const q = query(collection(fbdb, paths[iot].path));
    const unsubscribe = onSnapshot(q, ({ docs }) => {
      const data = docs.map((e) => ({ id: e.id, ...e.data() }));
      console.log(data);
      // dispatch(setIotData({ data, iot }));
    });
    return () => unsubscribe();
  }
}

export async function addDocumentByPathAndId(path, data) {
  const documentRef = doc(fbdb, path);
  const documentSnapshot = await getDoc(documentRef);

  if (documentSnapshot.exists()) {
    return false;
  } else {
    await setDoc(documentRef, data);
    return true;
  }
}

export async function setDocumentByPath(path, data) {
  const q = query(doc(fbdb, path));
  await setDoc(q, data);
}

export async function getDocumentByPath(path) {
  const q = query(doc(fbdb, path));
  const result = await getDoc(q);
  return result.data();
}

export async function getBlogs(path) {
  const q = query(collection(fbdb, path), orderBy("modifiedOn", "desc"));
  const { docs } = await getDocs(q);
  const result = docs.map((e) => ({ id: e.id, ...e.data() }));
  return result;
}

export async function getLatestBlogs(path, number) {
  const q = query(collection(fbdb, path), orderBy("modifiedOn", "desc"), limit(number));
  const { docs } = await getDocs(q);
  const result = docs.map((e) => ({ id: e.id, ...e.data() }));
  return result;
}

export function addDocumentByPath(path, data) {
  const q = query(collection(fbdb, path));
  addDoc(q, data);
}
