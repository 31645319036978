import { Box } from "@mui/material";
import FirebaseAuthUI from "../../services/firebase/FirebaseAuthScreen";

export default function Login() {
  return (
    <Box width={"calc(100vw)"} mt={15}>
      <FirebaseAuthUI />
    </Box>
  );
}
