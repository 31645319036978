import { OAuthProvider, EmailAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getAuth } from "firebase/auth";

import App from "./App";

new OAuthProvider("microsoft.com");
export const uiConfig = {
  signInSuccessUrl: `./home`,
  signInOptions: [
    { provider: EmailAuthProvider.PROVIDER_ID, disableSignUp: { status: true, adminEmail: "contact@sospapa-moris.com", helpLink: undefined } },
  ],
};

const Auth = getAuth(App);
setPersistence(Auth, browserLocalPersistence);

export default Auth;
