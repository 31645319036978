// import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import { Toolbar } from "@mui/material";

import { isLoggedInRS } from "../redux/UserStateSlice";

import NotFound from "./../../screens/NotFound/NotFound";
import Topbar from "./../../Components/Topbar/Topbar";
import Home from "../../screens/Home/Home";
import AboutUs from "../../screens/AboutUs/AboutUs";
import ContactUs from "../../screens/ContactUs/ContactUs";
import Login from "../../screens/Login/Login";
import LoggedOut from "../../screens/LoggedOut/LoggedOut";
import CreatePost from "../../screens/CreatePost/CreatePost";
import News from "../../screens/News/News";
import Blog from "./../../screens/Blog/Blog";
// import UnderConstruction from "../../screens/UnderConstrction/UnderConstruction";
import EditPost from "../../screens/EditPost/EditPost";

// const LoadingSuspense = lazy(() => import("./LoadingSuspense"));

function Router() {
  const isLoggedIn = useSelector(isLoggedInRS);

  return (
    // <Suspense fallback={<LoadingSuspense />}>
      <Box component="main" sx={{ pt: 3 }}>
        <Topbar />
        <Toolbar />
        <Routes>
          {isLoggedIn ? (
            <>
              <Route path="/create-post" element={<CreatePost />} />
              <Route path="/edit-post" element={<EditPost />} />
            </>
          ) : (
            <></>
          )}
          <Route path="/logged-out" element={<LoggedOut />} />
          <Route path="/blogs/:blogid" element={<Blog />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    // </Suspense>
  );
}

export default Router;
