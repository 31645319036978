import { Box, Typography } from "@mui/material";
import {
  FacebookIcon,
  // FacebookMessengerIcon,
  // FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  // TelegramIcon,
  // TelegramShareButton,
  // TwitterIcon,
  // TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export default function ShareButtons({ blogid }) {
  return (
    // <Box p={2} display={"flex"} alignItems={"center"}>
      <Box alignItems={"center"} pt={1} display={"grid"} gridTemplateColumns={"140px 40px 40px 40px"}>
        <Box>
          <Typography height={32}>Share this article: </Typography>
        </Box>
        <WhatsappShareButton url={`https://sospapa-moris.com/blogs/${blogid}`}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <FacebookShareButton url={`https://sospapa-moris.com/blogs/${blogid}`}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton url={`https://sospapa-moris.com/blogs/${blogid}`}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        {/* <FacebookMessengerShareButton url={`https://sospapa-moris.com/blogs/${blogid}`}>
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
        <TwitterShareButton url={`https://sospapa-moris.com/blogs/${blogid}`}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <TelegramShareButton url={`https://sospapa-moris.com/blogs/${blogid}`}>
          <TelegramIcon size={32} round />
        </TelegramShareButton> */}
      </Box>
    // </Box>
  );
}
