import React, { useEffect } from "react";

import "./App.css";
import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Router from "./services/router/Router";
import { useDispatch } from "react-redux";
import { setupApp } from "./screens/ContentScreen/Utils";
import setFlavour from "./services/mui/flavour";
import { SnackbarProvider } from "notistack";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const theme = setFlavour();
  const dispatch = useDispatch();

  useEffect(() => {
    setupApp(dispatch);
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <HelmetProvider>
          <div className="App">
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <Router />
            </Box>
          </div>
        </HelmetProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
