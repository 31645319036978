import styled from "@emotion/styled";
import { Autocomplete, Box, Button, Chip, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { addDocumentByPathAndId } from "../../services/firebase/DbUtils";
import { useSnackbar } from "notistack";
import Auth from "../../services/firebase/Auth";
import { uploadFileToPath } from "../../services/firebase/Storage";

const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: blue;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: blue;
    }
  }
`;

export default function CreatePost() {
  const { enqueueSnackbar } = useSnackbar();
  const [blogId, setBlogId] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [body, setBody] = useState("");
  const [tags, setTags] = useState([]);
  const [source, setSource] = useState("");

  function handleAutoComplete(event, value) {
    setTags(value);
  }

  async function createPost() {
    const docId = blogId.trim().toLowerCase();
    const result = await addDocumentByPathAndId(`/blogs/${docId}`, {
      title,
      body,
      tags,
      // image,
      source,
      createdOn: new Date(),
      createdBy: Auth.currentUser.email,
      modifiedOn: new Date(),
      modifiedBy: Auth.currentUser.email,
    });
    await uploadFileToPath(image, `/blogs/${docId}.jpg`);
    if (result) {
      setBody("");
      // setImage("");
      setTitle("");
      setSource("");
      setTags([]);
      enqueueSnackbar("Blog created successfully", { variant: "success" });
    } else {
      enqueueSnackbar("Blog already exists. Go to EDIT POST to update it.", { variant: "error" });
    }
  }

  return (
    <Box width={"calc(100vw)"} p={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledTextField fullWidth label="Blog ID" value={blogId} onChange={({ target: { value } }) => setBlogId(value.substring(0, 40))} />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField fullWidth label="Title" value={title} onChange={({ target: { value } }) => setTitle(value)} />
        </Grid>
        <Grid item xs={12}>
          {/* <StyledTextField fullWidth label="Post Image (Google Drive File ID)" value={image} onChange={({ target: { value } }) => setImage(value)} /> */}
          <Button fullWidth variant="contained" component="label">
            Upload Image
            <input type="file" hidden accept=".jpg" onChange={({ target: { files } }) => setImage(files[0])} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <StyledTextField fullWidth multiline rows={15} label="Body (HTML)" value={body} onChange={({ target: { value } }) => setBody(value)} />
          {/* <StyledTextField fullWidth label="Body (Google File ID)" value={body} onChange={({ target: { value } }) => setBody(value)} /> */}
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            clearIcon={false}
            options={[]}
            value={tags}
            onChange={handleAutoComplete}
            freeSolo
            multiple
            renderTags={(value, props) => tags.map((option, index) => <Chip label={option} {...props({ index })} />)}
            renderInput={(params) => <StyledTextField label="Add Keywords" {...params} />}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField fullWidth label="Source" value={source} onChange={({ target: { value } }) => setSource(value)} />
        </Grid>
        <Grid item md={2} xs={12}>
          <Button
            onClick={createPost}
            sx={{
              width: "100%",
              backgroundColor: "#3c52b2",
              color: "#fff",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            Create Post
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
