import React from "react";
// import ReactDOM from "react-dom/client";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./services/redux/store";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
const APP2 = (
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);
// );


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP2, rootElement);
} else {
  render(APP2, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
